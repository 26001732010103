import { type Ref } from 'vue'
import axios from 'axios';

import { useUserStore } from '@/stores/user'

export const fetchData = async (url: string, token: Ref<string>, initData: string, options: object = {}) => {
  const { getUserData } = useUserStore()
  const userData = await getUserData(initData);

  try {
    const { data } = await axios.post(url, {
      ...userData,
      options
    }, 
      {
        headers: {
          'X-CSRF-Token': token.value
        }
      }
    )

    if (data.locale.error) {
      console.error('Error fetching data:', data.locale.message);
    }

    return data;
  } catch(error) {
    console.error('Error fetching data:', error);
  }
}
