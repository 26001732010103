<template>
  <Main>
    <div class="captcha">
      <svg class="icon">
        <use xlink:href="@/assets/icons/icons.symbol.svg#fingerprint"></use>
      </svg>
      <form @submit.prevent="submitFormData">
        <label for="not-robot">
          <input type="checkbox" id="not-robot" name="notRobot">
          <span>{{ setup.locale.not_robot }}</span>
        </label>
        <Button>{{ setup.locale.submit }}</Button>
      </form>
    </div>
  </Main>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia'

  import { useControllerStore } from '@/stores/controller'

  const { setInitControllerData, setCaptchaData } = useControllerStore()
  const { setup } = storeToRefs(useControllerStore())

  const props = defineProps<{
    controller: string;
    csrf_token: string
  }>()

  const submitFormData = (event: Event) => {
    const formData = new FormData(event.target as HTMLFormElement);
    const checked = formData.has('notRobot');

    if (checked) {
      setCaptchaData(checked)
    }
  }

  setInitControllerData(props);
</script>

<style scoped lang="scss">
  .captcha {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100dvh - 100px);
    form {
      width: 100%;
    }
    label {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1.5rem;
    }
    span {
      display: inline-block;
      margin: 0 var(--space-half);
      font-family: 'Roboto';
    }
    input {
      margin-right: var(--space-half);
      transform: scale(2.2);
      accent-color: var(--input-bg-color);
    }
    svg {
      width: 180px;
      height: 180px;
      margin-bottom: 80px;
    }
  }
</style>
