
<template>
  <Main>
    <h1>{{ setup.locale.title }}</h1>
    <img
      :src="getImageUrl(setup.locale.img as string)"
      :alt="`${ setup.locale.title }`"
      height="162"
      width="380"
    >
    <h2>{{ setup.locale.about_title }}</h2>
    <p>{{ setup.locale.about_desc }}</p>
  
    <AnchorLinks
      :items="setup.locale.anchor_links"
    />

    <h2>{{ setup.locale.generals_title }}</h2>
    <p>{{ setup.locale.generals_desc }}</p>

    <Accordion
      :items="setup.locale.generals"
    />

    <h4>{{ setup.locale.importants_title }}</h4>
    <TwoSideLine>
      <Accordion
        :items="setup.locale.importants"
      />
    </TwoSideLine>
    <h2 id="fan-service">{{ setup.locale.fanservices_title }}</h2>
    <p>{{ setup.locale.fanservices_desc }}</p>

    <Accordion
      :items="setup.locale.fanservices"
    />

    <h2 id="moderation">{{ setup.locale.moderations_title }}</h2>
    <p>{{ setup.locale.moderations_desc }}</p>
  
    <Accordion
      :items="setup.locale.moderations"
    />
  
    <h2 id="support">{{ setup.locale.support_title }}</h2>
    <div v-html="setup.locale.support_desc"></div>
  </Main>
</template>

<script lang="ts" setup>
  import { storeToRefs } from 'pinia'

  import { useControllerStore } from '@/stores/controller'
  import { getImageUrl } from '@/composables/images'

  const { setInitControllerData } = useControllerStore()
  const { setup } = storeToRefs(useControllerStore())

  const props = defineProps<{
    controller: string;
    csrf_token: string
  }>()

  setInitControllerData(props);
</script>

<style lang="scss">
</style>
