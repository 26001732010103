
<template>
  <Main>
    <div class="lottery">
      <div class="content">
        <img
          :src="getImageUrl(setup.locale.img as string)"
          :alt="`${ setup.locale.text }`"
          height="250"
          width="250"
        >
        <h1>
          {{ setup.locale.text }}
        </h1>
      </div>
    </div>
    <ButtonCloseApp />
  </Main>
</template>

<script lang="ts" setup>
  import { storeToRefs } from 'pinia'
  import { useControllerStore } from '@/stores/controller'
  import { getImageUrl } from '@/composables/images'

  const { setInitControllerData } = useControllerStore()
  const { setup } = storeToRefs(useControllerStore())

  const props = defineProps<{
    controller: string;
    csrf_token: string
  }>()

  setInitControllerData(props);
</script>

<style lang="scss">
.lottery {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100dvh - 100px);
  .content {
    font-size: 28px;
    text-align: center;
  }
  img {
    max-width: 280px;
  }
  h1 {
    font-family: 'ComicsSans', sans-serif;
    font-size: 1.5rem;
    transform: translateY(-40px)
  }
}
</style>
