<template>
  <main v-if="setup.loaded" @click="(event) => copyCommandTextToBuffer(event, `${ setup.base_locale.copy }`)">
    <Message v-if="setup.locale.message" :setup />
    <slot v-else ></slot>
  </main>
  <Preloader v-else />
  <MainButton/>
</template>

<script setup lang="ts">
  import { onMounted } from 'vue';
  import { storeToRefs } from 'pinia'

  import { setThemeType, setCustomColorTheme } from '@/composables/templateSettings';
  import { copyCommandTextToBuffer } from '@/composables/notification';
  import { useControllerStore } from '@/stores/controller'

  const { setup } = storeToRefs(useControllerStore())
  const { setControllerData } = useControllerStore()

  onMounted(() => {
    setControllerData();
    setThemeType();
    setCustomColorTheme();
  });
</script>

<style lang="scss">
</style>
