<template>
  <Button class="close-app" @click="close">
    Ok
  </Button>
</template>

<script setup lang="ts">
  import { useWebApp } from 'vue-tg'
  const { close } = useWebApp()
</script>

<style scoped lang="scss">
  .close-app {
    text-transform: uppercase;
  }
</style>
