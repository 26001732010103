import { defineStore } from 'pinia';
import { useWebApp } from 'vue-tg';

import { setTemplateLanguage } from '@/composables/templateSettings';
import { fetchData } from '@/composables/fetchData'

import { Setup, InitControllerData } from '@/types/controller'
const { initData } = useWebApp()

interface Options {
  browser?: boolean;
  [key: string]: any;
}

export const useControllerStore = defineStore('controller', () => {
  const token = ref('');
  const controller = ref('');

  const setup = ref<Setup>({
    loaded: false,
    locale: {},
    base_locale: {},
    language_code: '',
  });

  const setInitControllerData = (props: InitControllerData) => {
    token.value = props.csrf_token
    controller.value = props.controller
  };

  const setControllerData = async () => {
    const options: Options = {};
    if (initData === '') {
      options.empty_data = true;
    }

    const data = await fetchData(`/${controller.value}/set_user`, token, initData, options)

    Object.assign(setup.value, data);
    setTemplateLanguage(setup.value.language_code);
  }

  const setCaptchaData = async (isCheked: boolean) => {
    const options = {
      captcha: isCheked
    }

    const data = await fetchData(`/${controller.value}/set_user`, token, initData, options)
    Object.assign(setup.value, data);
  }

  return {
    token,
    controller,
    setup,
    setInitControllerData,
    setControllerData,
    setCaptchaData
  }
})
